.image-button, .image-button img {
  cursor:pointer;
  width:32px;
  height:32px;
  border-radius: 50%;
  display:inline-block;
  background-size:100%;
}

.ib-create  {background-image:url("/img/slt-icon-create.png");}
.ib-edit    {background-image:url("/img/slt-icon-edit.png");}
.ib-delete  {background-image:url("/img/slt-icon-delete.png");}