body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 60px;
  background-image:url("/img/sub-bg-01.png");
}

html {
  position: relative;
  min-height: 100%;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 41px;
  background-color: transparent;
}

.footer>.container {
  width: auto;
  max-width: 680px;
  padding: 0 15px;
  text-align:center;
  margin-bottom:20px;
}
.container .text-muted {
  margin: 20px 0;
}

/* .footer>.container>img{
  max-width:200px;
} */

*{
  font-family:'Roboto', sans-serif;
}

.main-div{
  background-color:#f8f8f8;;
  padding:25px;
  border-radius:30px;
  margin-bottom:20px;
}

@media (max-width: 767px) {
  .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
  }
  .main-div{
    padding:0px;
    border-radius:0px;
  }
  .row {
    margin-right: 0px;
    margin-left: 0px;
  }
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 5px;
    padding-left: 5px;
  }
  
}    

/*.table>tbody>tr>td{
  border-top: 1px solid rgba(73,143,205, 1);
}*/

.table>thead>tr>th,.nav-tabs {
    border-bottom: 2px solid black;
}

h4{
  margin-top:15px;
}

p {
    margin: 0 0 3px;
}

.nav-tabs>li>a, h4{
    font-weight:800;
    color:rgba(0,119,188,1);
}

.crud-table tbody tr {
  border-bottom:1px solid black;
}

.crud-table tbody tr td {
  border-top:none;
  vertical-align:middle;
}

.crud-table tr td:nth-last-child(1) div{
  float:right;
  margin-bottom:0px;
}

.crud-table tr td:nth-last-child(1) div.image-button {
  margin-left:5px;
}

.form-actions button {
  margin-right:10px
}