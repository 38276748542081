@media (max-width: 768px){
  .navbar {
    margin-bottom:1px;
  }
  
  .navbar-nav {
    margin:1px -15px;
  }
  .navbar-default .navbar-nav li.logout {
    background-color: red;
  }
  
  .navbar-default .navbar-nav>li {
    background-color: rgb(73, 143, 205);
  }

  .navbar-default .navbar-nav>li>a {
    color: white;
  }
}

nav.navbar .container{
  font-family:'Roboto', sans-serif;
  font-weight:800;
}

.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover{
  border-bottom-color: rgba(255,255,255,0.5);
}

.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus{
  background:none;
}

.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover{
  background-color: rgb(18, 107, 32);
  border:1px solid transparent;
  border-bottom-color:rgb(18, 107, 32);
  color:white;
}

.nav>li>a:focus, .nav>li>a:hover {
  background:none;
}
