@media print {
  @page {
    /* size: A4 portrait; */
    margin: 0.5cm 0.5cm 0.5cm 1.5cm;
  }
  body {
    font-size: 12pt;
  }
  .table-responsive {
    overflow-x: visible !important;
  }
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    width: fit-content;
  }
  .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
  }
  .main-div {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .page-header {
    margin: 0;
    padding: 2em 0 1em;
  }
  .page-header .row {
    display: inline-block;
    margin: 0 0.5em;
    vertical-align: top;
  } 
  h1 {
    margin: 0 1em 0 0;
    font-size: 18pt;
  }
  h3 {
    margin: 10px 1em 0 0;
    font-size: 16pt;
  }
  h4 {
    margin: 15px 20px;
    font-size: 14pt;
  }
  .form-group {
    margin-bottom: 1em;
  }
  .btn {
    padding: 5px 0;
    font-size: 12pt;
    border: none;
  }
  .rw-widget>.rw-widget-container {
    width: 5em;
    border: none;
  }
  .rw-btn {
    display: none;
  }
  .crud-table tbody tr td.tbl-ctrls {  
    display: none;
  }
  
  .crud-table tr th.hidden-lg,
  .crud-table tr td.hidden-lg{
    display: none!important;
  }
  .crud-table tr th.hidden-xs,
  .crud-table tr td.hidden-xs,
  .crud-table tr th.hidden-sm,
  .crud-table tr td.hidden-sm,
  .crud-table tr th.hidden-md,
  .crud-table tr td.hidden-md
  {
    display: table-cell!important;
  }
  .crud-table tbody tr td {
    padding: 2px 5px;
  }
  .crud-table tbody tr td:nth-child(1) {
    white-space: nowrap;
  }  
  .tbl-cards{
    display: none!important;
  }
  .tbl-normal {
    display: block!important;
  } 
}
